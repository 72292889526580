import { ReactComponent as IconCopy } from "assets/images/icons/project-icons/copy.svg";
import React from "react";
import { useLocation } from "react-router-dom";
import DateFormat from "utils/dateFormat";
import { toast } from "react-toastify";
import DropdownNew from "utils/dropdown-new";
import Pagination from "components/common/pagination";
import CurrencyFormat from "utils/currencyFormat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { isValidJSON } from "utils";

export const AllLoansTable = ({
	handleToggleMakeLoanPaymentModal,
	allLoansPayload,
	loading,
	setLoanAccountId,
}) => {
	const location = useLocation();
	const handleCopyItem = (item, itemLabel) => {
		navigator.clipboard.writeText(item);
		toast(`${itemLabel} Copied`);
	};

	return (
		<div className="mt-2">
			<div className="table-container">
				<table className="table table-auto table-rounded table-border">
					<thead>
						<tr>
							<th>
								<div className="flex items-center">Date</div>
							</th>
							<th>Name of User</th>
							<th>User's Email</th>
							<th>User ID</th>
							<th>Is Loan Repaid</th>
							<th>Next Repayment Date</th>
							<th>Recurring Amount</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{loading === true && (
							<tr>
								<td colSpan="8">
									<div className="table-info">
										<FontAwesomeIcon
											icon="spinner"
											spin
										/>
										<div className="font-bold uppercase">
											Loading
										</div>
									</div>
								</td>
							</tr>
						)}

						{loading === false && !allLoansPayload?.data && (
							<tr>
								<td colSpan="8">
									<div className="table-info">
										<FontAwesomeIcon icon="unlink" />
										<div className="font-bold uppercase">
											An error occurred
											<br />
											Please try again later.
										</div>
									</div>
								</td>
							</tr>
						)}

						{loading === false &&
							allLoansPayload?.data &&
							allLoansPayload?.data.length <= 0 && (
								<tr>
									<td colSpan="8">
										{(allLoansPayload?.page <=
											allLoansPayload?.totalPages ||
											allLoansPayload?.data?.length <
												1) && (
											<div className="table-info">
												<FontAwesomeIcon icon="list" />
												<div className="font-bold uppercase">
													No results found
												</div>
											</div>
										)}

										{allLoansPayload?.page >
											allLoansPayload?.totalPages &&
											allLoansPayload?.data?.length >
												0 && (
												<div className="table-info">
													<FontAwesomeIcon icon="exclamation-triangle" />
													<div className="font-bold uppercase">
														Invalid Page Number
													</div>
													<div className="mt-2">
														<Link
															to="?page=1"
															className="btn btn-ep-blue btn-sm mx-auto"
														>
															Goto Page 1
														</Link>
													</div>
												</div>
											)}
									</td>
								</tr>
							)}

						{loading === false &&
							allLoansPayload?.data &&
							allLoansPayload?.data?.length > 0 &&
							allLoansPayload?.data?.map((item, index) => {
								const userRawData = item?.data;
								const userData = isValidJSON(userRawData)
									? JSON.parse(item?.data)
									: null;
								return (
									<tr key={index}>
										<td>
											<div>
												{DateFormat(
													item?.createdAt?.split(
														"T"
													)[0]
												)}
											</div>
										</td>
										<td>
											<div className="whitespace-nowrap capitalize">
												{item?.fullName || "N/A"}
											</div>
										</td>
										<td>
											<div className="whitespace-nowrap">
												{item?.email || "N/A"}
											</div>
										</td>
										<td>
											<div className="flex items-center">
												{item?.userId?.substring(
													0,
													8
												) || "-"}
												<span
													onClick={() =>
														handleCopyItem(
															item?.userId,
															"User ID"
														)
													}
													className="ml-1 cursor-pointer"
												>
													<IconCopy />
												</span>
											</div>
										</td>
										<td>
											{item?.collectionIsCompleted
												? "Yes"
												: "No"}
										</td>
										<td>
											{DateFormat(
												item?.nextRepaymentDate?.split(
													"T"
												)[0]
											) || "N/A"}
										</td>
										<td>
											{CurrencyFormat(
												item?.recurringAmount / 100 ||
													"0"
											)}
										</td>
										<td>
											<DropdownNew>
												<></>
												<div>
													<div
														onClick={() => {
															setLoanAccountId(
																item?.id
															);
															handleToggleMakeLoanPaymentModal();
														}}
														className="dropdown-item dropdown-item-icon"
													>
														Make Payment
													</div>
												</div>
											</DropdownNew>
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>

			{loading === false &&
				allLoansPayload?.data &&
				allLoansPayload?.data.length > 0 && (
					<div>
						<Pagination
							data={allLoansPayload}
							url={location.search}
							limit={allLoansPayload?.limit}
						/>
					</div>
				)}
		</div>
	);
};
